<template>
  <div class="chat-container">
    <div class="chat-main">
      <div class="chat-video" :id="remoteStream && remoteStream.sid"></div>
    </div>
    <div class="chat-self">
      <span class="item-icon">
        <svg-icon icon-class="camera_off" />
      </span>
      <div class="chat-video" :id="localStream && localStream.sid"></div>
    </div>
    <a class="chat-btn chat-switch" v-if="localStream && cameras.length > 1" @click="switchCamera">
      <svg-icon icon-class="camera_switch" />
    </a>
    <div class="chat-btns" v-if="inited">
      <a class="chat-btn" @click="togglePublish">
        <svg-icon :icon-class="localStream ? 'camera' : 'camera_off'" />
      </a>
      <a class="chat-btn danger" @click="handleOff">
        <svg-icon icon-class="call" />
      </a>
      <a class="chat-btn" @click="toggleAudio">
        <svg-icon :icon-class="isMute ? 'micphone_off' : 'micphone'" />
      </a>
      <el-tooltip :content="inRecord ? '点击停止录制' : '点击开始录制'">
        <a class="chat-btn" :class="{disabled: !inRecord}" @click="toggleRecord">
          <svg-icon icon-class="record" />
        </a>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import sdk, { Client } from 'urtc-sdk';
import { mapState, mapGetters } from 'vuex'
import room from '@/api/room'
import config from '@/utils/urtc'

const { AppId } = config

export default {
  name: 'Chat',
  data: function () {
    return {
      roomId: '',
      isJoinedRoom: false,
      inRecord: false,
      localStream: null,
      remoteStream: null,
      isMute: false,
      cameras: [],
      currentCameraDeviceId: null,
      inited: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['user']),
    roomStatus: function () {
      return this.isJoinedRoom ? '已加入' : '未加入';
    },
  },
  created: function () {
    if (!AppId) {
      alert('请先设置 AppId');
      return;
    }
    if (this.isAdmin) {
      this.$message.error('必须已使馆身份使用该功能')
      return;
    }
    const { room_id } = this.$route.query
    this.roomId = room_id
    if (!room_id) {
      this.$message.error('房间不存在')
      return;
    }
  },
  mounted: function () {
    this.init()
  },
  beforeDestroy: function () {
    console.info('component will destroy');
    window.removeEventListener('beforeunload', this.handleLeaveRoom);
    this.handleLeaveRoom();
  },
  destroyed: function () {
    this.isComponentDestroyed = true;
  },
  methods: {
    toggleRecord() {
      if (!this.client) {
        this.$message.error('当前环境无法录制')
        return;
      }
      if (this.inRecord) {
        this.client.stopRecord((err, ret) => {
          if (err) {
            this.$message.error(err)
            return;
          }
          this.inRecord = false
        })
      }
      else {
        this.client.startRecord({
          bucket: 'murop',
          region: 'cn-bj'
        }, (err, ret) => {
          if (err) {
            this.$message.error(`录制失败：${err}`)
            return;
          }
          this.inRecord = true
        })
      }
    },
    async init() {
      const token = await room.entry(this.roomId)
      sdk.getDevices({ camera: true }, res => {
        this.cameras = res.filter(v => v.kind === 'videoinput')
      })
      this.inited = true
      this.client = new Client(AppId, token);
      this.client.getCameras({ camera: true }, res => {
        this.currentCameraDeviceId = res.filter(v => v.kind === 'videoinput')?.deviceId
      })
      this.client.on('stream-published', (localStream) => {
        console.info('stream-published: ', localStream);
        this.localStream = localStream
        this.$nextTick(() => {
          this.client.play({
            streamId: localStream.sid,
            container: localStream.sid,
            mute: true || this.isMute,
            fit: 'contain',
          });
        });
      });
      this.client.on('stream-added', (remoteStream) => {
        console.info('stream-added: ', remoteStream);
        this.remoteStream = remoteStream
        // 自动订阅
        this.client.subscribe(remoteStream.sid, (err) => {
          console.error('自动订阅失败：', err);
        });
      });
      this.client.on('stream-subscribed', (remoteStream) => {
        console.info('stream-subscribed: ', remoteStream);
        this.$nextTick(() => {
          this.client.play({
            streamId: remoteStream.sid,
            container: remoteStream.sid,
            fit: 'contain',
          });
        });
      });
      this.client.on('stream-removed', (remoteStream) => {
        console.info('stream-removed: ', remoteStream);
      });
      this.client.on('connection-state-change', ({ previous, current }) => {
        console.log(`连接状态 ${previous} -> ${current}`);
      });
      this.client.on('stream-reconnected', ({ current }) => {
        console.log(`流已断开重连`);
        this.$nextTick(() => {
          this.client.play({
            streamId: current.sid,
            container: current.sid
          });
        });
      });

      window.addEventListener('beforeunload', this.handleLeaveRoom);

      this.handleJoinRoom()
    },
    handleJoinRoom: function () {
      const { roomId, isJoinedRoom } = this;
      if (isJoinedRoom) {
        alert('已经加入了房间');
        return;
      }
      if (!roomId) {
        alert('请先填写房间号');
        return;
      }
      this.client.joinRoom(roomId, this.user.diplomaticDTO.id.toString(), () => {
        console.info('加入房间成功');
        this.isJoinedRoom = true;

        // 默认发布本地的第一条
        this.handlePublish()
      }, (err) => {
        console.error('加入房间失败： ', err);
      });
    },
    handlePublish: function () {
      this.client.publish(err => {
        console.error(`发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`);
      });
    },
    handlePublishScreen: function () {
      this.client.publish({ audio: false, video: false, screen: true }, (err) => {
        console.error(`发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`);
      });
    },
    toggleAudio() {
      if (this.isMute) {
        this.localStream && this.client.unmuteAudio(this.localStream.sid)
      }
      else {
        this.localStream && this.client.muteAudio(this.localStream.sid)
      }
      this.isMute = !this.isMute
    },
    togglePublish() {
      if (!this.localStream) {
        this.handlePublish()
      }
      else {
        this.handleUnpublish()
      }
    },
    handleUnpublish: function () {
      const { localStream } = this;
      if (!localStream) {
        //alert('未选择需要取消发布的本地流');
        return;
      }
      this.client.unpublish(localStream.sid, (stream) => {
        console.info('取消发布本地流成功：', stream);
        this.localStream = null
      }, (err) => {
        console.error('取消发布本地流失败：', err);
      });
    },
    handleSubscribe: function () {
      const { remoteStream } = this;
      if (!remoteStream) {
        alert('未选择需要订阅的远端流');
        return;
      }
      this.client.subscribe(remoteStream.sid, (err) => {
        console.error('订阅失败：', err);
      });
    },
    handleUnsubscribe: function () {
      const { remoteStream } = this;
      if (!remoteStream) {
        alert('未选择需要取消订阅的远端流');
        return;
      }
      this.client.unsubscribe(remoteStream.sid, (stream) => {
        console.info('取消订阅成功：', stream);
        this.remoteStream = null
      }, (err) => {
        console.error('订阅失败：', err);
      });
    },
    handleLeaveRoom: function () {
      const { isJoinedRoom } = this;
      if (!isJoinedRoom) {
        return;
      }
      this.client.leaveRoom(() => {
        console.info('离开房间成功');
        this.localStream = null;
        this.remoteStream = null;
        this.isJoinedRoom = false;
      }, (err) => {
        console.error('离开房间失败：', err);
      });
    },
    switchCamera() {
      const currentIndex = this.cameras.findIndex(v => v.deviceId === this.currentCameraDeviceId)
      const nextDevice = this.cameras[currentIndex >= this.cameras.length - 1 ? 0 : currentIndex + 1]
      this.client.switchDevice({
        type: 'video',
        deviceId: nextDevice.deviceId
      })
    },
    handleOff() {
      this.$confirm('您确定要你开房间吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        this.client && this.client.leaveRoom(() => {
          this.$router.push('/video/list')
        })
      })
    }
  }
};
</script>

<style lang="less" scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.chat {
  &-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &-main {
    width: 100%;
    height: 100%;
    background: #313131;
  }
  &-btns {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .chat-btn {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &-btn {
    display: inline-block;
    padding: 8px;
    border-radius: 50%;
    background-color: #1989fa;
    color: #fff;
    font-size: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    .svg-icon {
      font-size: 24px;
    }
    &.danger {
      background-color: #ee0a24;
      padding: 16px;
    }
    &.disabled {
      background-color: #aaa;
    }
  }
  &-switch {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  &-self {
    position: absolute;
    bottom: 96px;
    right: 30px;
    border: 2px solid #fff;
    overflow: hidden;
    border-radius: 4px;
    width: 240px;
    height: 135px;
    .item-icon {
      font-size: 24px;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -12px;
      margin-top: -12px;
    }
  }
  &-video {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .chat-self {
    width: 90px;
    height: 160px;
  }
}
</style>
